<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
       
        div.main-content(id="content")
            div.layout-px-spacing
                div.row.mt-3
                    div.col-lg-12.col-md-12.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                div.widget-content.widget-content-area
                                    div.col-lg-12.mx-auto
                                        iframe(:src="examUrl" width="1260px" height="640px")

</template>
<script>
/* eslint-disable */
export default {
    name: 'TakeExam',
    props: ['sURL'],
    components: {
    },    
    data() {
        return {
            examUrl: '../../assets/' + this.sURL.replace('-', '/') + '.html',
        }
    },
    methods: {
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
    }
}
</script>